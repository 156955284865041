import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Heading } from '../Heading';
import { routesEnum } from '../../Routes';
import { Link } from '../Link';
import SocialInstagram from '../../static/custom/social-instagram.svg';
import SocialTwitter from '../../static/custom/social-twitter.svg';
import SocialLinkedIn from '../../static/custom/social-linkedin.svg';
import SocialTelegram from '../../static/custom/social-telegram.svg';
import SocialDiscord from '../../static/custom/social-discord.svg';
import SocialReddit from '../../static/custom/social-reddit.svg';
import SocialMedium from '../../static/custom/social-medium.svg';
import SocialGithub from '../../static/custom/social-github.svg';
import LuksoLogo from '../../static/custom/lukso-logo.svg';

const FooterWrapper = styled.div`
  background: var(--neutral-100);
`;

const FooterUpper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 40px 40px 0;
  background-color: var(--neutral-100);
  gap: 70px;
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const FooterMiddle = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  gap: 48px;
  padding-top: 32px;
  margin: 32px 40px 40px;
  background-color: var(--neutral-100);
  border-top: 1px var(--neutral-95) solid;
  @media screen and (max-width: 1000px) {
    grid-template-columns: auto;
  }
  @media screen and (max-width: 650px) {
    gap: 32px;
    padding-top: 40px;
    margin-top: 40px;
  }
`;

const MiddleLinks = styled.div`
  display: flex;
  gap: 40px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  @media screen and (max-width: 1000px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
    width: 224px;
  }
`;

const Logo = styled.img``;

const FooterBottom = styled.div`
  padding: 27px 40px;
  display: flex;
  justify-content: space-between;
  background-color: var(--neutral-95);
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  gap: 32px;
`;

export const CustomFooter = () => {
  return (
    <FooterWrapper>
      <FooterUpper>
        <div className="col extra-links">
          <Heading level={4}>
            <FormattedMessage defaultMessage="Staking Launchpad" />
          </Heading>
          <Link to={routesEnum.acknowledgementPage}>
            <FormattedMessage defaultMessage="Deposit" />
          </Link>
          <Link to={routesEnum.checklistPage}>
            <FormattedMessage defaultMessage="Checklist" />
          </Link>
          <Link to={routesEnum.FaqPage}>
            <FormattedMessage defaultMessage="Staking FAQ" />
          </Link>
        </div>
        <div className="col extra-links">
          <Heading level={4}>
            <FormattedMessage defaultMessage="More on staking" />
          </Heading>
          <Link to={routesEnum.phishingPage}>
            <FormattedMessage defaultMessage="Avoid phishing" />
          </Link>
          <Link to="https://docs.google.com/spreadsheets/d/108XDdgKIxtnXr22-2jur9ciyY2fOHwoNbu_miXRgOy0/">
            <FormattedMessage defaultMessage="Staking economics" />
          </Link>
          <Link to="https://github.com/runtimeverification/deposit-contract-verification/blob/96434de/deposit-contract-verification.pdf">
            <FormattedMessage defaultMessage="Formal verification report" />
          </Link>
        </div>
      </FooterUpper>
      <FooterMiddle>
        <Link to={routesEnum.landingPage}>
          <Logo src={LuksoLogo} alt="LUKSO Logo" />
        </Link>
        <MiddleLinks>
          <Link to="https://lukso.network/faq">
            <FormattedMessage defaultMessage="LUKSO FAQs" />
          </Link>
          <Link to="mailto:support@lukso.network">
            <FormattedMessage defaultMessage="Need Help?" />
          </Link>
          <Link to="https://github.com/lukso-network">
            <FormattedMessage defaultMessage="Github" />
          </Link>
        </MiddleLinks>
        <SocialLinks className="col extra-links">
          <Link to="https://twitter.com/lukso_io">
            <img src={SocialTwitter} alt="Twitter" />
          </Link>
          <Link to="https://instagram.com/lukso">
            <img src={SocialInstagram} alt="Instagram" />
          </Link>
          <Link to="https://www.linkedin.com/company/18289660">
            <img src={SocialLinkedIn} alt="LinkedIn" />
          </Link>
          <Link to="https://t.me/lukso">
            <img src={SocialTelegram} alt="Telegram" />
          </Link>
          <Link to="https://discord.gg/lukso">
            <img src={SocialDiscord} alt="Discord" />
          </Link>
          <Link to="https://reddit.com/r/lukso">
            <img src={SocialReddit} alt="Reddit" />
          </Link>
          <Link to="https://medium.com/lukso">
            <img src={SocialMedium} alt="Medium" />
          </Link>
          <Link to="https://github.com/lukso-network">
            <img src={SocialGithub} alt="Github" />
          </Link>
        </SocialLinks>
      </FooterMiddle>
      <FooterBottom>
        <BottomLinks>
          <Link to="/">
            <FormattedMessage defaultMessage="Privacy Policy" />
          </Link>
          <Link to={routesEnum.termsOfServicePage}>
            <FormattedMessage defaultMessage="Terms & Conditions" />
          </Link>
        </BottomLinks>
      </FooterBottom>
    </FooterWrapper>
  );
};
